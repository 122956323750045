<template>
  <el-container>
    <div class="w-100">
      <el-button
        type="primary"
        round
        class="single-button mar-t-10"
        @click="addPlan"
        >新建方案</el-button
      >
      <div v-loading="loading" class="source-plan mar-t-10">
        <div class="mod-list min-h-600 lh-60 pb-20 average-list">
          <ul v-if="plans.length >= 1">
            <li>
              <h3>
                <span class="num-tit">序号</span>
                <span class="tag mod-list-title2">方案名称</span>
                <span class="tag">涵盖信源数</span>
                <span class="tag">设定状态</span>
                <span class="tag">公开</span>
                <span class="tag badge-dangermod-list-title1">创建者</span>
                <span class="tag">操作</span>
                <!-- <span class="tag">编辑</span>
                <span class="tag">置顶</span>
                <span class="tag">删除</span> -->
              </h3>
            </li>
            <li
              v-for="(plan, index) in plans"
              :key="index"
              :id="`scroll_plan_${plan.pid}`"
            >
              <div class="item">
                <span class="num bg-555" v-if="index == 0">1</span>
                <span class="num bg-689" v-else-if="index == 1">2</span>
                <span class="num bg-8ea" v-else-if="index == 2">3</span>
                <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                <span class="tag mod-list-title2">{{ plan.name }}</span>
                <span class="tag">{{ plan.exps.length }}</span>
                <span class="tag">
                  <el-switch
                    v-model="plan.enable"
                    :active-value="1"
                    :inactive-value="0"
                    @change="changeState(plan)"
                  ></el-switch>
                </span>
                <span class="tag">
                  <i
                    class="iconfont icon-gongkai"
                    v-if="plan.public === 'open'"
                  ></i>
                  <i class="iconfont icon-bugongkai jy" v-else></i>
                </span>
                <span class="tag mod-list-title1">{{
                  plan.createdby_name
                }}</span>
                <span class="tag">
                  <el-tooltip
                    effect="dark"
                    content="修改方案"
                    placement="bottom"
                    ><i class="iconfont icon-bianji" @click="update(plan)"></i
                  ></el-tooltip>
                  <el-tooltip
                    effect="dark"
                    content="置顶方案"
                    placement="bottom"
                    ><i
                      class="iconfont icon-zhiding color-ccc"
                      v-if="index === 0"
                    ></i>
                    <i
                      class="iconfont icon-zhiding"
                      @click="topping(plan)"
                      v-else
                    ></i
                  ></el-tooltip>
                  <el-tooltip
                    effect="dark"
                    content="删除方案"
                    placement="bottom"
                    ><i
                      class="iconfont icon-shanchu"
                      @click="delPlan(plan, index)"
                    ></i
                  ></el-tooltip>
                </span>
              </div>
            </li>
          </ul>

          <el-row v-else class="empty">
            <div>
              <img
                src="@/assets/images/common/empty/xy_fagl.svg"
                alt="信源方案管理"
                width="400"
                class="img"
                type="image/svg+xml"
              />
              <p>没有方案，点击上方新建按钮新建一个吧</p>
            </div>
          </el-row>
          <!-- 列表数据 -->
          <div class="new-add hide">
            <div class="mar-t-10">
              <el-button
                plain
                class="bd-n mar-t-10"
                icon="iconfont icon-zengjia"
                @click="newEdit = !newEdit"
                >新增定向信源方案</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-delete
      ref="confirm"
      title="删除方案"
      confirm="删除"
      @must="mustDelPlan"
    />
  </el-container>
</template>
<script type="application/javascript">
import confirmDelete from '@components/common/confirm.vue';
import { getPlans as sourcePlans, toping as toToping } from '@/api/plan';
import { deleteSourcePlan, changeState } from '@/api/source';
import { mapGetters } from 'vuex';
const _ = window._;
export default {
  components: {
    confirmDelete
  },
  props: ['editedPlan'],
  data() {
    return {
      value2: '',
      labelPosition: 'left',
      multipleSelection: [],
      plans: [],
      newEdit: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['user', 'bundle'])
  },
  mounted() {
    this.getPlans();
  },
  methods: {
    getPlans() {
      this.loading = true;
      sourcePlans({ enable: 'all', type: 'ids' }).then(res => {
        if (res.data.state) {
          const { data } = res.data;
          this.plans = data.map(item => {
            item.edited = false;
            item.exps = JSON.parse(item.exps);
            return item;
          });
          // 设置滚动条位置
          if (this.editedPlan) {
            this.$nextTick(() => {
              let plan = _.find(this.plans, { pid: this.editedPlan });
              if (plan !== undefined) {
                // 可编辑方案
                plan.edited = true;
                document.getElementsByClassName(
                  'el-main'
                )[0].scrollTop = window.document.getElementById(
                  `scroll_plan_${this.editedPlan}`
                ).offsetTop;
              }
            });
          }
        } else {
          this.$message.error(res.data.error);
        }
        this.loading = false;
      });
    },
    addPlan() {
      this.$router.push({ name: 'source-create-plan' });
    },
    update(plan) {
      this.$router.push({
        name: 'source-update-plan',
        params: { data: JSON.stringify(plan) }
      });
    },
    topping(plan) {
      this.$message(`“${plan.name}”方案置顶中...`);
      let top = _.maxBy(this.plans, o => {
        return o.top;
      });
      toToping({ id: plan.pid, top: top.top + 1 })
        .then(res => {
          if (res.data.state) {
            this.$message.success('置顶成功');
            this.getPlans();
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(err => {
          window.console.error(err);
        });
    },
    addDone() {
      this.getPlans();
      this.newEdit = false;
      // 方案套餐数量
      this.bundle.plans.remain = this.bundle.plans.remain - 1;
      this.$store.dispatch('storeBundle', this.bundle);
    },
    changeState(plan) {
      changeState({ id: plan.pid, enable: plan.enable }).then(res => {
        if (res.data.state) {
          this.$message.success('更新方案状态成功');
        } else {
          this.$message.error('更新方案状态失败');
          plan.enable = plan.enable === 1 ? 0 : 1;
        }
      });
    },
    delPlan(plan, index) {
      let textBox = [
        { type: 'error', text: '您将不能再收到此方案的预警信息' },
        { type: 'success', text: '使用此方案数据生成历史报告将会被保留' }
      ];
      this.$refs.confirm.show(textBox, { id: plan.pid, index });
    },
    mustDelPlan(data) {
      const { id, index } = data;
      deleteSourcePlan({ id })
        .then(res => {
          if (res.data.state) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            if (this.plans[index].enable == 1) {
              // 方案套餐数量
              this.bundle.plans.remain = this.bundle.plans.remain + 1;
              this.$store.dispatch('storeBundle', this.bundle);
            }
            this.plans.splice(index, 1);
          } else {
            this.$message({
              type: 'error',
              message: res.data.error
            });
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped>
.source-plan .mod-list-title2 {
  width: 22%;
}
.source-plan .mod-list-title1 {
  width: 18%;
}
.source-plan span.tag:nth-child(7),
.source-plan span.tag:nth-child(8),
.source-plan span.tag:nth-child(9) {
  width: 8%;
}
.source-plan li {
  height: auto;
}
.new-add >>> .el-button + .edit-cont {
  margin-top: 20px;
}
</style>
