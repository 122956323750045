<template>
  <el-container class="source-caution">
    <div class="w-100" v-loading="loading.plans">
      <el-row class="empty" v-if="!loading.plans && plans.length < 1">
        <div>
          <img
            src="@/assets/images/common/empty/xy_yjgl.svg"
            alt="信源预警"
            width="400"
            class="img"
            type="image/svg+xml"
          />
          <p>
            <span>空空的，快来新增信源信息吧</span>
            <el-button type="primary" round @click="changeIndex"
              >新增信源方案</el-button
            >
          </p>
        </div>
      </el-row>
      <div v-else class="source-caution">
        <div class="mod-list min-h-600 lh-60 pb-20 average-list">
          <ul>
            <li>
              <h3>
                <span class="num-tit">序号</span>
                <span class="tag">方案名称</span>
                <span class="tag">设定状态</span>
                <span class="tag">编辑</span>
                <span class="tag">预警条件</span>
              </h3>
            </li>
            <li v-for="(plan, index) in plans" :key="index" class="item">
              <div class="flex">
                <span class="num bg-555" v-if="index == 0">1</span>
                <span class="num bg-689" v-else-if="index == 1">2</span>
                <span class="num bg-8ea" v-else-if="index == 2">3</span>
                <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                <span class="tag">{{ plan.name }}</span>
                <span class="tag">
                  <el-switch
                    v-model="plan.notify"
                    :active-value="1"
                    :inactive-value="0"
                    @change="changeState(plan)"
                  ></el-switch>
                </span>
                <span class="tag">
                  <i class="iconfont icon-bianji" @click="toCaution(plan)" />
                </span>
                <span class="tag">{{ plan.where }}</span>
              </div>
            </li>
          </ul>
        </div>
        <el-card class="yjgl min-h-600 hide">
          <div v-for="(plan, index) in plans" :key="index">
            <div class="yjgl_cont">
              <el-row class="mar-t-5 cftj hide">
                <el-col class="w-115">
                  <span class="yjgl_name">触发条件</span>
                  <el-tooltip class="item" effect="dark" placement="right">
                    <i class="iconfont icon-wenhao"></i>
                    <div slot="content">
                      每小时信息数量达到20，且敏感信息占比比前1小时增加10%，触发<br />报警。
                    </div>
                  </el-tooltip>
                </el-col>
                <el-col class="lh-30 w-790"> </el-col>
              </el-row>
              <el-row class="mar-t-5 hide">
                <el-col class="w-115">
                  <span class="yjgl_name">推送方式</span>
                  <el-tooltip class="item" effect="dark" placement="right">
                    <i class="iconfont icon-wenhao"></i>
                    <div slot="content">
                      PC若为不公开方案仅能推送给自己，邮箱与微信推送仅能推送给<br />
                      通讯录中有填写邮箱地址和微信号的人; 实时预警不支援邮箱推送
                    </div>
                  </el-tooltip>
                </el-col>
              </el-row>
              <el-row class="mar-t-5 hide">
                <el-col class="w-115">
                  <span class="yjgl_name">副标题</span>
                </el-col>
                <el-col class="lh-30 w-790 sub_title">
                  <div class="hide">
                    <div>
                      <el-radio
                        v-model="plan.condition.subtitle.value[0]"
                        label="hide_title"
                        >不显示
                      </el-radio>
                    </div>
                    <div>
                      <el-radio
                        v-model="plan.condition.subtitle.value[0]"
                        label="show_title"
                        >显示
                      </el-radio>
                      <div class="item-big-input">
                        <el-input
                          type="text"
                          placeholder="请输入副标内容"
                          v-model="plan.condition.subtitle.show_title[0]"
                          maxlength="30"
                          show-word-limit
                          :disabled="
                            plan.condition.subtitle.value[0] === 'hide_title'
                              ? true
                              : false
                          "
                        />
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-card>
      </div>
      <contact-dialog v-on:addDone="loadContact" ref="contact" />
    </div>
  </el-container>
</template>
<script>
import { themeList, timeRange } from '@/utils/constants';
import contactDialog from '@components/common/new-contacts.vue';
import {
  warningList as sourceWarningList,
  warningUpdate,
  warningNotify
} from '@/api/caution';
import { users as customerUsers } from '@/api/customer';
import { getContacts } from '@/api/contacts';
export default {
  components: {
    contactDialog
  },
  data() {
    return {
      text: '',
      themeList,
      timeRange,
      value1: '',
      radio: 'presently',
      checkList: ['push_night', 'push_week'],
      plans: [],
      contacts: { wechat: [], email: [] },
      loading: { plans: false, edited: false },
      triggerCustomize: {
        quantity: '',
        prev_percent: '',
        neg_percent_rise: '',
        neg_percent: ''
      }
    };
  },
  mounted() {
    this.loadData();
    this.loadContact();
  },
  methods: {
    wechatContactChange(plan) {
      if (plan.condition.terminal.wechat.indexOf('new_contact') !== -1) {
        this.$refs.contact.show();
        plan.condition.terminal.wechat = plan.condition.terminal.wechat.filter(
          function(val) {
            return val !== 'new_contact';
          }
        );
      }
    },
    emailContactChange(plan) {
      if (plan.condition.terminal.email.indexOf('new_contact') !== -1) {
        this.$refs.contact.show();
        plan.condition.terminal.email = plan.condition.terminal.email.filter(
          function(val) {
            return val !== 'new_contact';
          }
        );
      }
    },
    save(data) {
      let plan = JSON.parse(JSON.stringify(data));
      if (plan.condition.period.value == 'timer') {
        var timer = [];
        if (!plan.condition.period.timer_setting.num) {
          this.$message.warning('请选择定时周期');
          return false;
        }
        if (plan.condition.period.timer_setting.one === '') {
          this.$message.warning('请选择定时时间');
          return false;
        }
        timer.push(plan.condition.period.timer_setting.one);
        if (plan.condition.period.timer_setting.num == 2) {
          if (plan.condition.period.timer_setting.two === '') {
            this.$message.warning('请选择定时时间');
            return false;
          }
          timer.push(plan.condition.period.timer_setting.two);
        }
        plan.condition.period.timer = timer;
      }
      plan.condition.term.grade.value = plan.condition.term.grade.value.split(
        ','
      );
      plan.condition.term.emotion.value = plan.condition.term.emotion.value.split(
        ','
      );
      plan.condition.trigger.option.quantity.val =
        plan.condition.trigger.option.quantity.val === '自定义'
          ? this.triggerCustomize.quantity
          : plan.condition.trigger.option.quantity.val;
      plan.condition.trigger.option.prev_percent.val =
        plan.condition.trigger.option.prev_percent.val === '自定义'
          ? this.triggerCustomize.prev_percent
          : plan.condition.trigger.option.prev_percent.val;
      plan.condition.trigger.option.neg_percent_rise.val =
        plan.condition.trigger.option.neg_percent_rise.val === '自定义'
          ? this.triggerCustomize.neg_percent_rise
          : plan.condition.trigger.option.neg_percent_rise.val;
      plan.condition.trigger.option.neg_percent.val =
        plan.condition.trigger.option.neg_percent.val === '自定义'
          ? this.triggerCustomize.neg_percent
          : plan.condition.trigger.option.neg_percent.val;
      plan.condition.trigger.option.quantity.val =
        plan.condition.trigger.option.quantity.val >= 10000000
          ? 10000000
          : plan.condition.trigger.option.quantity.val;
      plan.condition.trigger.option.prev_percent.val =
        plan.condition.trigger.option.prev_percent.val >= 1000
          ? 1000
          : plan.condition.trigger.option.prev_percent.val;
      plan.condition.trigger.option.neg_percent_rise.val =
        plan.condition.trigger.option.neg_percent_rise.val >= 100
          ? 100
          : plan.condition.trigger.option.neg_percent_rise.val;
      plan.condition.trigger.option.neg_percent.val =
        plan.condition.trigger.option.neg_percent.val >= 100
          ? 100
          : plan.condition.trigger.option.neg_percent.val;
      for (let i = 0; i < plan.condition.trigger.value.length; i++) {
        let option = plan.condition.trigger.value[i];
        if (plan.condition.trigger.option[option].val === '') {
          this.$message.warning('请输入触发条件自定义数值');
          return false;
        }
      }
      if (_.size(plan.condition.terminal.value) < 1) {
        this.$message.warning('请选择预警推送方式');
        return false;
      }
      for (let i in plan.condition.terminal.value) {
        var terminal = plan.condition.terminal.value[i];
        if (_.size(plan.condition.terminal[terminal]) < 1) {
          this.$message.warning('请选择' + terminal + '预警推送方式联系人');
          return false;
        }
      }
      if (plan.condition.period.value == 'presently') {
        plan.condition.terminal.value = plan.condition.terminal.value.filter(
          function(val) {
            return val != 'email';
          }
        );
        plan.condition.terminal.email = [];
      }
      if (
        plan.condition.subtitle.value[0] === 'show_title' &&
        !plan.condition.subtitle.show_title[0]
      ) {
        this.$message.error('请填写副标题内容');
        return false;
      }
      plan.condition.subtitle.show_title =
        plan.condition.subtitle.value[0] === 'show_title'
          ? plan.condition.subtitle.show_title
          : [];
      var newPlan = JSON.parse(JSON.stringify(plan));
      delete newPlan.name;
      delete newPlan.checkAll;
      delete newPlan.createdby;
      delete newPlan.edited;
      newPlan.enable = newPlan.enable == 1 ? true : false;
      delete newPlan.isIndeterminate;
      delete newPlan.condition.period.timer_setting;
      delete newPlan.where;
      delete newPlan.users;
      this.loading.edited = true;
      warningUpdate(newPlan)
        .then(res => {
          if (res.data.state) {
            this.$message.success('更新方案预警成功');
            this.loadData();
          } else {
            this.$message.error(res.data.error);
          }
          this.loading.edited = false;
        })
        .catch(res => {
          this.loading.edited = false;
          this.$message.error('保存失败，服务错误');
        });
    },
    loadData() {
      this.loading.plans = true;
      sourceWarningList({ enable: 'all', type: 'ids' })
        .then(res => {
          if (res.data.state) {
            const { data } = res.data;
            const _this = this;
            this.plans = data.map(plan => {
              plan.edited = false;
              plan.users = [];
              plan.condition.period.timer_setting = {
                num: _.size(plan.condition.period.timer),
                one:
                  _.size(plan.condition.period.timer) >= 1
                    ? plan.condition.period.timer[0]
                    : '',
                two:
                  _.size(plan.condition.period.timer) == 2
                    ? plan.condition.period.timer[1]
                    : ''
              };
              plan.checkAll =
                plan.condition.term.source.option.length ===
                plan.condition.term.source.value.length;
              plan.isIndeterminate =
                plan.condition.term.source.value.length > 0 &&
                plan.condition.term.source.value.length <
                  plan.condition.term.source.option.length;
              plan.condition.term.emotion.value = plan.condition.term.emotion.value.join(
                ','
              );
              plan.condition.term.grade.value = plan.condition.term.grade.value.join(
                ','
              );
              plan.users = [];
              if (
                plan.condition.trigger.option.quantity.vals.indexOf(
                  plan.condition.trigger.option.quantity.val
                ) === -1
              ) {
                _this.triggerCustomize.quantity =
                  plan.condition.trigger.option.quantity.val;
                plan.condition.trigger.option.quantity.val = '自定义';
              }
              if (
                plan.condition.trigger.option.prev_percent.vals.indexOf(
                  plan.condition.trigger.option.prev_percent.val
                ) === -1
              ) {
                _this.triggerCustomize.prev_percent =
                  plan.condition.trigger.option.prev_percent.val;
                plan.condition.trigger.option.prev_percent.val = '自定义';
              }
              if (
                plan.condition.trigger.option.neg_percent_rise.vals.indexOf(
                  plan.condition.trigger.option.neg_percent_rise.val
                ) === -1
              ) {
                _this.triggerCustomize.neg_percent_rise =
                  plan.condition.trigger.option.neg_percent_rise.val;
                plan.condition.trigger.option.neg_percent_rise.val = '自定义';
              }
              if (
                plan.condition.trigger.option.neg_percent.vals.indexOf(
                  plan.condition.trigger.option.neg_percent.val
                ) === -1
              ) {
                _this.triggerCustomize.neg_percent =
                  plan.condition.trigger.option.neg_percent.val;
                plan.condition.trigger.option.neg_percent.val = '自定义';
              }
              plan.condition.terminal.email = _.filter(
                plan.condition.terminal.email,
                id => {
                  let row = _.find(_this.contacts.email, { id: id });
                  return row !== undefined;
                }
              );
              plan.condition.terminal.wechat = _.filter(
                plan.condition.terminal.wechat,
                id => {
                  let row = _.find(_this.contacts.wechat, { id: id });
                  return row !== undefined;
                }
              );
              plan.where = _this.calculationWhere(plan);
              return plan;
            });
          } else {
            this.$message.error(res.data.error);
          }
          this.loading.plans = false;
        })
        .catch(err => {
          window.console.error(err);
        });
    },
    handleTimerangeChange(item) {
      const { value } = item;
      if (value.indexOf('push_night') > -1) {
        item.push_night = ['22:00', '08:00'];
      } else {
        item.push_night = ['', ''];
      }
    },
    toCaution(plan) {
      this.$router.push({ name: 'source-caution', params: { plan: plan } });
    },
    planUsers(plan) {
      plan.edited = !plan.edited;
      customerUsers({ plan_id: plan.plan_id })
        .then(res => {
          if (res.data.state) {
            plan.users = res.data.data;
            let uids = plan.users.map((item, index) => {
              return item.id;
            });
            // 过滤已删除用户ID
            plan.condition.terminal.web = plan.condition.terminal.web.filter(
              id => {
                return uids.indexOf(id) !== -1;
              }
            );
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error('加载方案用户失败，服务错误');
        });
    },
    loadContact() {
      getContacts()
        .then(res => {
          if (res.data.state) {
            var email = [];
            var wechat = [];
            let result = res.data.data;
            _(result).forEach((item, i) => {
              if (item.email)
                email.push({
                  id: item.id,
                  email: item.email,
                  name: item.name
                });
              if (item.openid)
                wechat.push({
                  id: item.id,
                  wechat: item.wechat,
                  name: item.name
                });
            });
            this.contacts.contacts = result;
            this.contacts.email = email;
            this.contacts.wechat = wechat;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('加载通讯录失败，服务错误');
        });
    },
    changeState(plan) {
      warningNotify({
        id: plan.plan_id,
        notify: plan.notify
      })
        .then(res => {
          if (res.data.state) {
            this.$message.success('更新状态成功');
          } else {
            plan.notify = plan.notify == 0 ? 1 : 0;
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('更新状态失败，服务错误');
        });
    },
    hourFormat(hour, type) {
      var val = '';
      switch (type) {
        case 'second':
          val = parseInt(hour) * 3600;
          break;
        case 'hour':
          val = hour < 10 ? '0' + hour + ':00:00' : hour + ':00:00';
          break;
        default:
          window.console.error('format type not allowed');
          break;
      }
      return val;
    },
    calculationWhere(plan) {
      var where = '';
      switch (plan.condition.period.value) {
        case 'timer':
          where += '定时预警，';
          break;
        case 'frequency':
          where += '循环预警，';
          break;
        case 'presently':
          where += '实时预警，';
          break;
      }
      where +=
        plan.condition.mute.value.indexOf('push_week') !== -1
          ? '周末不预警，'
          : '周末预警，';
      where += '推送方式: ' + plan.condition.terminal.value.join('，');
      return where;
    },
    changeIndex() {
      this.$emit('changeIndex', 'plan');
    }
  }
};
</script>
<style scoped>
.w-276 {
  width: 276px;
}
.source-caution li {
  height: auto;
}
/* .source-caution .mod-list-title1{width:20%;} */
.source-caution .form-box {
  margin-top: 60px;
}
.source-caution >>> .el-radio-group,
.source-caution .form-box li {
  line-height: 30px;
}
.source-caution >>> .el-button span {
  float: none;
}
.source-caution >>> .el-checkbox:last-of-type {
  margin-right: 30px;
}
.source-caution >>> .el-radio-group {
  margin-top: 10px;
}
.source-caution h3 .tag:last-child,
.source-caution .item .tag:last-child {
  flex: 3;
}
</style>
