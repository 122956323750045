<template>
  <el-container class="staatistics-cont">
    <div class="w-100">
      <el-row class="empty" v-if="!loading.plans && plans.length < 1">
        <div>
          <img src="@/assets/images/common/empty/xy_kshtj.svg" alt="可视化统计" width="400" class="img" type="image/svg+xml"/>
          <p>
            <span>空空的，快来新增信源信息吧</span>
            <el-button type="primary" round @click="toPlan()">新增信源方案</el-button>
          </p>
        </div>
      </el-row>
      <div v-loading="loading.plans" v-else class="source-statistics">
        <!-- 表单 -->
        <el-card class="box-card yq-form pb-10 demo-form-inline pad-lr-20" id="statistics-card">
          <el-form
            ref="form"
            label-width="100px"
            label-position="left"
            class="form-box">
            <el-form-item label="选择方案">
              <el-select v-model="where.plan_id" placeholder="请选择方案" class="w-160 mar-r-20" @change="loadData()" :disabled="loading.main">
                <el-option v-for="(plan, index) in plans" :key="index" :value="plan.pid" :label="plan.name">
                  <span>{{ plan.name }}</span>
                  <i class="iconfont icon-bianji f-r" @click="editedPlan(plan.pid)" v-if="user.isadmin || user.id === plan.createdby"></i>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分析时间" class="w-50">
              <el-date-picker
                :disabled="loading.main"
                v-model="where.dateSpan"
                @change="dateSpanChange"
                :picker-options="{disabledDate: dateRange, shortcuts: pickerOptions.shortcuts}"
                :editable="false"
                :clearable="false"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetimerange"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right" class="mar-r-20">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="情感属性" class="w-50">
              <el-radio-group v-model="where.emotion">
                <el-radio label="all">全部</el-radio>
                <el-radio label="negative">敏感</el-radio>
                <el-radio label="positive">非敏感</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="来源再锁定" class="w-50">
              <el-radio-group v-model="where.allow_source_focus">
                <el-radio :label="false">不锁定</el-radio>
                <el-radio :label="true">锁定</el-radio>
              </el-radio-group>
              <el-select
                      v-model="where.source_focus"
                      multiple collapse-tags placeholder="选择锁定源"
                      v-show="where.allow_source_focus"
                      class="w-280 mar-l-10">
                <el-option v-for="(item, index) in plan.exps" :value="index" :label="getName(item)" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="信息地区" class="w-50">
              <el-radio-group v-model="where.area">
                <el-radio label="all">全部</el-radio>
                <el-radio label="cn">境内</el-radio>
                <el-radio label="other">境外</el-radio>
                <el-radio label="customer">自定义</el-radio>
              </el-radio-group>
              <el-cascader
                  v-model="customerArea"
                  :options="provinceOptions"
                  @change="handleCityChange"
                  v-if="where.area === 'customer'" />
            </el-form-item>
            <el-form-item label="语言" class="w-50">
              <el-radio-group v-model="where.lang">
                <el-radio label="all">全部</el-radio>
                <el-radio label="zh">中文</el-radio>
                <el-radio label="en">其他</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>

          <div class="button-group mar-t-20">
            <el-button
              type="primary"
              round
              v-loading="loading.main"
              :disabled="loading.main"
              @click="loadData">查询</el-button>
            <el-button round @click="initWhere">重置</el-button>
          </div>
        </el-card>
        <div class="mar-in"></div>
        <!--@end 表单 -->
        <el-row>
          <el-card class="box-card mod-echarts-box mar-t-20 h-400" v-loading="loading.planSourceCount" id="planSourceCount">
            <div class="mod-title">
              <span>信源总量</span>
              <feature :filter="[]" v-on:downPhoto="downPhoto('planSourceCount')" :showDown="true" />
            </div>
            <div class="echarts_empty" v-show="data.count.length < 1">
              <div class="img"></div>
            </div>
            <div id="plan_source_count" v-show="data.count.length >= 1" class="mod-echarts-cont"></div>
          </el-card>
        </el-row>
        <!--@end 信源总量 -->
        <!-- 信源概览 -->
        <el-row>
          <el-card class="box-card mar-t-20 pos-r xygl" v-loading="loading.planSourceSummary" id="planSourceSummary">
            <div class="mod-title">
              <span>信源概览</span>
              <feature :filter="[]" v-on:downPhoto="downPhoto('planSourceSummary')" :showDown="true"/>
            </div>
            <div class="echarts_empty" v-show="data.summary.length < 1">
              <div class="img"></div>
            </div>
            <div class="mod-list mar-t-20 average-list" v-show="data.summary.length >= 1">
              <ul>
                <li>
                  <h3>
                    <span class="tag">信源名称</span>
                    <span class="tag">总信息量(条)</span>
                    <span class="tag">信源类型</span>
                    <span class="tag">情感</span>
                    <span class="tag">原发/转发</span>
                    <span class="tag">总转发或相似数</span>
                    <span class="tag">覆盖人数</span>
                    <span class="tag">Top1重要文章</span>
                  </h3>
                </li>
              </ul>
              <vue-scroll>
                <ul style="max-height:420px;">
                  <li v-for="(row, index) in data.summary" :key="index" class="item">
                    <span class="tag" :title="row.name">{{ row.name }}</span>
                    <span class="tag">{{ row.count }}</span>
                    <span class="tag">{{ row.category }}</span>
                    <span class="tag" :title="row.emotion">{{ row.emotion }}</span>
                    <span class="tag" :title="row.forward">{{ row.forward }}</span>
                    <span class="tag" :title="row.category+'总转发'+row.forward_count">{{ row.category }}总转发{{ row.forward_count }}</span>
                    <span class="tag">{{ row.include_count }}</span>
                    <span class="tag" @click="detail(row.top_hit.rowkey)" :title="row.top_hit.title">{{ row.top_hit.title }}</span>
                  </li>
                </ul>
              </vue-scroll>
            </div>
          </el-card>
        </el-row>
        <!--@end 信源概览 -->
        <el-row :gutter="20" class="mar-t-20 el-row-20">
          <!-- 信源声量走势 -->
          <el-col :span="12">
            <el-card class="ov-v box-card mod-echarts-box h-400" v-loading="loading.planSourceCountStat" id="planSourceCountStat">
              <div class="mod-title">
                <span>信源声量走势</span>
                <feature
                  :filter="filterData.stat"
                  v-on:filter="filterStat"
                  v-on:downPhoto="downPhoto('planSourceCountStat')"
                  :showDown="true" />
              </div>
              <div class="echarts_empty" v-show="data.countStat.length < 1">
                <div class="img"></div>
              </div>
              <div id="plan_source_count_stat"  class="mod-echarts-cont" v-show="data.countStat.length >= 1"></div>
            </el-card>
          </el-col>
          <!--@end 信源声量走势 -->
          <!-- 信源敏感信息走势 -->
          <el-col :span="12">
            <el-card class="box-card mod-echarts-box ov-v h-400" v-loading="loading.planSourceCountStatNeg" id="planSourceCountStatNeg">
              <div class="mod-title">
                <span>信源敏感信息走势</span>
                <feature
                  :filter="filterData.statNeg"
                  v-on:filter="filterStatNeg"
                  v-on:downPhoto="downPhoto('planSourceCountStatNeg')"
                  :showDown="true"/>
              </div>
              <div class="echarts_empty" v-show="data.countStatNeg.length < 1">
                <div class="img"></div>
              </div>
              <div id="plan_source_count_stat_neg" v-show="data.countStatNeg.length >= 1" class="mod-echarts-cont"></div>
            </el-card>
          </el-col>
          <!--@end 信源敏感信息走势 -->
        </el-row>
        <el-row :gutter="20" class="mar-t-20 el-row-20">
          <!-- 信源声量占比 -->
          <el-col :span="12">
            <el-card class="box-card mod-echarts-box h-400" v-loading="loading.planSourceCountStatPercent" id="planSourceCountStatPercent">
              <div class="mod-title">
                <span>信源声量占比</span>
                <feature
                  :filter="[]"
                  v-on:downPhoto="downPhoto('planSourceCountStatPercent')"
                  :showDown="true"/>
              </div>
              <div class="echarts_empty h-320" v-show="data.countStatPercent.length < 1">
                <div class="img"></div>
              </div>
              <div id="plan_source_count_stat_percent" v-show="data.countStatPercent.length >= 1" class="mod-echarts-cont"></div>
            </el-card>
          </el-col>
          <!--@end 信源声量占比 -->
          <!-- 信源情感分布 -->
          <el-col :span="12">
            <el-card class="box-card mod-echarts-box h-400" v-loading="loading.planSourceCountStatDistributed" id="planSourceCountStatDistributed">
              <div class="mod-title">
                <span>信源情感分布</span>
                <feature
                  :filter="[]"
                  v-on:downPhoto="downPhoto('planSourceCountStatDistributed')"
                  :showDown="true"/>
              </div>
              <div class="echarts_empty" v-show="data.countStatDistributed.length < 1">
                <div class="img"></div>
              </div>
              <div id="plan_source_count_stat_distributed" v-show="data.countStatDistributed.length >= 1" class="mod-echarts-cont"></div>
            </el-card>
          </el-col>
          <!--@end 信源情感分布 -->
        </el-row>
        <el-row :gutter="20" class="mar-t-20 el-row-20">
          <!-- 信源关键词 -->
          <el-col :span="12">
            <el-card class="box-card mod-echarts-box h-400" v-loading="loading.planSourceWords" id="planSourceWords">
              <div class="mod-title">
                <span>信源关键词</span>
                <feature
                  :filter="filterData.words"
                  v-on:filter="filterWords"
                  v-on:downPhoto="downPhoto('planSourceWords')"
                  :showDown="true"/>
              </div>
              <div class="echarts_empty h-340" v-show="data.sourceWords.length < 1">
                <div class="img"></div>
              </div>
              <div id="plan_source_words" v-show="data.sourceWords.length > 1" class="mod-echarts-cont"></div>
            </el-card>
          </el-col>
          <!--@end 信源关键词 -->
          <!-- 信源关键文章 -->
          <el-col :span="12">
            <el-card class="box-card mod-echarts-box h-400 xygjwz" v-loading="loading.planSourceArticles" id="planSourceArticles">
              <div class="mod-title">
                <span>信源关键文章</span>
                <feature
                  :filter="filterData.articles"
                  v-on:filter="filterArticles"
                  v-on:downPhoto="downPhoto('planSourceArticles')"
                  :showDown="true"/>
              </div>
              <div class="echarts_empty h-340" v-if="data.articles.length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list mar-t-10" v-else>
                <ul>
                  <li>
                    <h3>
                      <span class="num-tit">序号</span>
                      <span class="mod-list-title2">标题</span>
                      <span class="tag">重要分数</span>
                      <span class="mod-list-title1">信源</span>
                    </h3>
                  </li>
                  <li v-for="(item, index) in data.articles" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2 nowrap" @click="detail(item.rowkey)" :title="item.title">{{ item.title }}</a>
                    <span class="tag">{{ item.weight }}</span>
                    <span class="mod-list-title1">{{ item.source_name }}</span>
                  </li>
                </ul>
              </div>
            </el-card>
          </el-col>
          <!--@end 信源关键文章 -->
        </el-row>
        <!-- 相关账号 -->
        <el-row>
          <el-card class="pos-r mar-t-20 xgzh" v-loading="loading.planSourceAccount" id="planSourceAccount">
            <div class="mod-title">
              <span>相关账号</span>
              <feature
                :filter="filterData.account"
                v-on:filter="filterAccount"
                v-on:downPhoto="downPhoto('planSourceAccount')"
                :showDown="true"/>
            </div>
            <div class="echarts_empty" v-show="data.account.length < 1">
              <div class="img"></div>
            </div>
            <div class="mod-list mar-t-20" v-show="data.account.length >= 1">
              <ul>
                <li>
                  <h3>
                    <span class="mod-list-title1">账号名称</span>
                    <span class="tag">总信息量(条)</span>
                    <span class="tag">信源类型</span>
                    <span class="tag">情感</span>
                    <span class="tag">关系</span>
                    <span class="mod-list-title2">相关文章</span>
                  </h3>
                </li>
                <li v-for="(item, index) in data.account" :key="index">
                  <span class="mod-list-title1" :title="item.name">{{ item.name }}</span>
                  <span class="tag">{{ item.total }}</span>
                  <span class="tag">{{ item.category }}</span>
                  <span class="tag" :title="item.emotion">{{ item.emotion }}</span>
                  <span class="tag" :title="item.relation">{{ item.relation }}</span>
                  <span class="mod-list-title2 nowrap" @click="detail(item.rowkey)" :title="item.article">{{ item.article }}</span>
                </li>
              </ul>
            </div>
          </el-card>
        </el-row>
        <!--@end 相关账号 -->
        <el-row :gutter="20" class="mar-t-20 el-row-20">
          <!-- 相关实体 -->
          <el-col :span="12">
            <el-card class="box-card mod-echarts-box h-400" v-loading="loading.planSourceEntity" id="planSourceEntity">
              <div class="mod-title">
                <span>相关实体</span>
                <feature
                  :filter="filterData.entity"
                  v-on:filter="filterEntity"
                  v-on:downPhoto="downPhoto('planSourceEntity')"
                  :showDown="true"/>
              </div>
              <div class="echarts_empty h-340" v-show="data.entity.length < 1">
                <div class="img"></div>
              </div>
              <div id="source-entity" v-show="data.entity.length >= 1" class="mod-echarts-cont"></div>
            </el-card>
          </el-col>
          <!--@end 相关实体 -->
          <!-- 事件关联 -->
          <el-col :span="12">
            <el-card class="box-card mod-echarts-box h-400" v-loading="loading.planSourceRelationEvent" id="planSourceRelationEvent">
              <div class="mod-title">
                <span>事件关联</span>
                <feature
                  :filter="[]"
                  v-on:downPhoto="downPhoto('planSourceRelationEvent')"
                  :showDown="true"/>
              </div>
              <div class="echarts_empty h-340" v-show="data.relationEvent.length < 1">
                <div class="img"></div>
              </div>
              <div id="source-relation-event" class="mod-echarts-cont" v-show="data.relationEvent.length >= 1"></div>
            </el-card>
          </el-col>
          <!--@end 事件关联 -->
        </el-row>
      </div>
      <relat-infor ref="infor" />
      <article-detail ref="detail" />
      <article-list ref="list" />
    </div>
  </el-container>
</template>
<script>
import { dateRangeDisabled, sourceFeature, sourceName, dom2image, globalFormatDate, globalCreateChart } from "@/utils/helpers";
import { pickerOptions, medias, province, provinceOptions } from '@/utils/constants';
import feature from '@components/common/feature.vue';
import articleDetail from '@components/common/article-detail.vue';
import relatInfor from '@components/common/relat-infor.vue';
import articleList from '@components/common/article-list.vue';
import {getPlans as sourcePlans} from '@/api/plan';
import {mapGetters} from 'vuex';
import {
  statisticsCount,
  statisticsSummary,
  statisticsCountStat,
  statisticsCountStatNeg,
  statisticsCountStatPercent,
  statisticsCountStatDistributed,
  statisticsWords,
  statisticsArticles,
  statisticsRelationAccount,
  statisticsRelationEvent,
  statisticsEntity
} from '@/api/source';
const _ = window._;
const d3 = require("d3");
const $ = window.$;
export default {
  data() {
    return {
      options: [],
      pickerOptions,
      provinceOptions,
      plans: [],
      plan: {exps: []}, // 当前选中方案
      loading: {
        plans: false, main: false, planSourceCount: false, planSourceSummary: false,
        planSourceCountStat: false, planSourceCountStatNeg: false, planSourceCountStatPercent: false,
        planSourceCountStatDistributed: false, planSourceWords: false, planSourceArticles: false,
        planSourceAccount: false, planSourceEntity: false, planSourceRelationEvent: false
      },
      customerArea: [], // 自定义地区
      where: {
        plan_id: null, dateSpanString: "", dateSpan: null, emotion: "all",
        focus: {op: "and", must: "", not: ""}, area: "all", area_cn: "北京",
        lang: 'all', media: [], source_focus: [], allow_source_focus: false,
        city: ''
      },
      filter: false,
      medias: medias,
      province: province,
      mediaCheckAll: false,
      data: {
        count: [], summary: [], countStat: [], countStatNeg: [],
        countStatPercent: [], countStatDistributed: [], sourceWords: [],
        articles: [], account: [], entity: [], relationEvent: []
      },
      filterData: {
        words: [],
        articles: [],
        account: [],
        entity: [],
        stat: [],
        statNeg: []
      },
      isDestroyed: false
    };
  },
  components: {
    feature,
    articleDetail,
    relatInfor,
    articleList
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  destroyed() {
    this.isDestroyed = true;
  },
  watch: {
    "where.plan_id": function(pid) {
      this.$store.commit('SET_SOURCE_FILTER', {
        key: 'plan_id',
        value: pid,
      });
      this.handleSourceFocus(pid)
    }
  },
  mounted() {
    this.getPlans()
  },
  methods: {
    downPhoto(id) {
      this.$nextTick(() => {
        dom2image(window.document.getElementById(id), id);
      });
    },
    editedPlan(pid) {
      this.$emit("toEditedPlan", pid)
    },
    handleCityChange(v) {
      if (v && v.length >= 2) {
        Object.assign(this.where, {
          area_cn: v[0],
          city: v[0] !== v[1] ? v[1] : ''
        })
      }
    },
    initWhere() {
      let cachePid = this.$store.state.config.sourceFilter.plan_id;
      let cacheSpan = this.$store.state.config.sourceFilter.span;
      let pid = this.plans[0].pid;
      let span = null
      if (cachePid) {
        let plan = _.find(this.plans, {pid: cachePid})
        if (plan !== undefined) {
          pid = cachePid
        }
      }
      if (cacheSpan) {
        span = cacheSpan.split("~")
      } else {
        // 默认24小时
        const start = new Date()
        const end = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        let begin = globalFormatDate(start)
        let now = globalFormatDate(end)
        span = [begin, now]
      }
      this.$store.commit('SET_SOURCE_FILTER', {
        key: 'plan_id',
        value: pid,
      });
      this.$store.commit('SET_SOURCE_FILTER', {
        key: 'span',
        value: span.join("~"),
      });
      Object.assign(this.where, {
        plan_id: pid,
        dateSpan: span,
        dateSpanString: span.join("~"),
        media: this.medias,
        emotion: 'all',
        focus: {op: "and", must: "", not: ""},
        area: "all",
        area_cn: "北京",
        city: '',
        lang: 'all',
        source_focus: [],
        allow_source_focus: false
      })
      this.mediaCheckAll = true
      this.handleSourceFocus(this.where.plan_id)
      this.loadData()
    },
    handleSourceFocus(pid) {
      this.plan = _.find(this.plans, ['pid', pid])
      let exps = _.isArray(this.plan.exps) ? this.plan.exps : JSON.parse(this.plan.exps)
      // 只能匹配审核成功的信源
      this.plan.exps = exps.filter((exp) => {
        return exp.state === 'succ'
      });
      this.where.source_focus = _.keys(this.plan.exps).map((n) => {
        return parseInt(n);
      });
    },
    setFilterOptions() {
      let sourceOptions = this.where.source_focus.map((index) => {
        const exp = this.plan.exps[index]
        return {label: `${sourceName(exp)}(${exp.category})`, value: JSON.stringify(exp)}
      })
      // 信源关键词筛选
      let filter = [
        {
          field: 'source_focus_data',
          value: _.first(sourceOptions),
          options: sourceOptions
        }, {
          field: 'source_switch',
          value: {label: '全部', value: 'all'},
          options: [{label: '全部', value: 'all'}, {label: '单一信源', value: 'single'}]
        }
      ]
      let singleFilter = [
        {
          field: 'source_focus_data',
          value: _.first(sourceOptions),
          options: sourceOptions
        }
      ]
      this.filterData.words = JSON.parse(JSON.stringify(filter))
      this.filterData.articles = JSON.parse(JSON.stringify(filter))
      this.filterData.account = JSON.parse(JSON.stringify(singleFilter))
      this.filterData.entity = JSON.parse(JSON.stringify(filter))

      let unit = [{
        field: 'unit',
        value: {label: '小时', value: 'hour'},
        options: [{label: '小时', value: 'hour'}, {label: '日', value: 'day'}, {label: '月', value: 'month'}]
      }]
      this.filterData.stat = JSON.parse(JSON.stringify(unit))
      this.filterData.statNeg = JSON.parse(JSON.stringify(unit))
    },
    loadData() {
      let modules = [
        "planSourceCount", "planSourceSummary", "planSourceCountStat", "planSourceCountStatNeg", "planSourceCountStatPercent",
        "planSourceCountStatDistributed", "planSourceWords", "planSourceArticles", "planSourceAccount", "planSourceEntity"
      ];
      let promiseQueue = [];
      let _this = this;
      _(modules).forEach((module) => {
        promiseQueue.push(() => {
          return new Promise((resolve, reject) => {
            _this[module](resolve);
          })
        })
      })
      async function queue(arr) {
        let res = null;
        for (let promise of arr) {
          // 页面被切换 断开
          if (_this.isDestroyed) {
            return false;
          }
          res = await promise(res);
        }
        return await res;
      }
      // promise 队列
      _this.loading.main = true;
      queue(promiseQueue).then(data => {
        _this.loading.main = false;
        _this.setFilterOptions();
      })
    },
    detail(rowkey) {
      let pid = this.where.plan_id;
      let keywords = [];
      let focusWords = this.where.focus.must;
      if (_.size(focusWords) >= 1) {
        let words = focusWords.split(',');
        _(words).forEach((word, index) => {
          if (keywords.indexOf(word) === -1) keywords.push(word);
        });
      }
      this.$refs.detail.showDetail(rowkey, 'source-statistics', keywords, pid);
    },
    getPlans() {
      this.loading.plans = true
      sourcePlans({ enable: 'enable', type: 'ids' }).then(res => {
        if (res.data.state) {
          const { data } = res.data
          this.plans = data.filter((plan) => {
            // 过滤没有信源的 或 没有审核通过信源的
            let exps = _.isArray(plan.exps) ? plan.exps : JSON.parse(plan.exps)
            let succExps = exps.filter((exp) => {
              return exp.state === 'succ'
            })
            return _.size(succExps) >= 1
          })
          // 初始化筛选条件
          if (_.size(this.plans) >= 1) {
            this.initWhere()
          }
        } else {
          this.$message.error(res.data.error)
        }
        this.loading.plans = false
      })
    },
    dateSpanChange(date) {
      if (date) {
        this.where.dateSpanString = `${date[0]}~${date[1]}`
        this.$store.commit('SET_SOURCE_FILTER', {
          key: 'span',
          value: `${date[0]}~${date[1]}`,
        });
        this.loadData()
      } else {
        this.where.dateSpanString = ""
      }
    },
    dateRange(date) {
      return dateRangeDisabled(date)
    },
    handleChange(val) {
      this.where.media = val;
      if (this.medias.every(i => this.where.media.indexOf(i) !== -1 )) {
        this.mediaCheckAll = true;
      } else {
        this.mediaCheckAll = false;
      }
      this.loadData()
    },
    sourceFlag(row) {
      return sourceFeature(row)
    },
    mediaChange() {
    },
    handleCheckAll() {
      // 将反选按钮重置
      const { mediaCheckAll, medias } = this;
      this.where.media = mediaCheckAll ? medias : [];
    },
    filterWords(field, option) {
      if (field === 'source_switch') {
        if (option.value === 'all') {
          this.planSourceWords()
        } else {
          let source = _.find(this.filterData.words, { field: 'source_focus_data' })
          if (source !== undefined) {
            this.planSourceWords(null, source.value.value)
          }
        }
      } else {
        let sourceSwitch = _.find(this.filterData.words, { field: 'source_switch' })
        if (sourceSwitch !== undefined) {
          if (sourceSwitch.value.value === 'all') {
            this.planSourceWords()
          } else {
            this.planSourceWords(null, option.value)
          }
        }
      }
    },
    filterArticles(field, option) {
      if (field === 'source_switch') {
        if (option.value === 'all') {
          this.planSourceArticles()
        } else {
          let source = _.find(this.filterData.articles, { field: 'source_focus_data' })
          if (source !== undefined) {
            this.planSourceArticles(null, source.value.value)
          }
        }
      } else {
        let sourceSwitch = _.find(this.filterData.articles, { field: 'source_switch' })
        if (sourceSwitch !== undefined) {
          if (sourceSwitch.value.value === 'all') {
            this.planSourceArticles()
          } else {
            this.planSourceArticles(null, option.value)
          }
        }
      }
    },
    filterAccount(field, option) {
      if (field !== 'source_switch') {
        this.planSourceAccount(null, option.value)
      }
    },
    filterEntity(field, option) {
      if (field === 'source_switch') {
        if (option.value === 'all') {
          this.planSourceEntity()
        } else {
          let source = _.find(this.filterData.entity, { field: 'source_focus_data' })
          if (source !== undefined) {
            this.planSourceEntity(null, source.value.value)
          }
        }
      } else {
        let sourceSwitch = _.find(this.filterData.entity, { field: 'source_switch' })
        if (sourceSwitch !== undefined) {
          if (sourceSwitch.value.value === 'all') {
            this.planSourceEntity()
          } else {
            this.planSourceEntity(null, option.value)
          }
        }
      }
    },
    filterStat(field, option){
      this.planSourceCountStat(null, option.value)
    },
    filterStatNeg(field, option){
      this.planSourceCountStatNeg(null, option.value)
    },
    getName(row) {
      return `${sourceName(row)}(${row.category})`;
    },
    // 绘制图表
    calculationWhere() {
      const { where, plan } = this
      const params = {
        plan_id: where.plan_id,
        span: where.dateSpanString,
        emotion: where.emotion,
        lang: where.lang,
        media: where.media.join(","),
        area_cn: where.area === 'customer' ? where.area_cn : where.area,
        city: where.city,
        focus: where.focus,
        field: 'content',
        similar: 'no'
      };
      // 来源再次锁定
      if (where.allow_source_focus) {
        if (_.size(where.source_focus) < 1) {
          this.$message.warning("请选择要锁定的信源")
          return false
        }
        params['source_focus'] = [];
        where.source_focus.map((index) => {
          params['source_focus'].push(plan.exps[index])
        })
        params['source_focus'] = JSON.stringify(params['source_focus']);
      }

      return params;
    },
    list(args = {}) {
      const _this = this;
      const params = this.calculationWhere();
      if (!params) return false;
      Object.assign(params, args);
      if (args.source_focus !== undefined && _.size(args.source_focus) >= 1) {
        params['source_focus'] = [];
        args.source_focus.map((index) => {
          params['source_focus'].push(_this.plan.exps[index]);
        });
        params['source_focus'] = JSON.stringify(params['source_focus']);
      }
      this.$refs.list.requestListData(`${window.service.api}/plan/plan_source_msg`, params);
    },
    planSourceCount(resolve = null) {
      const params = this.calculationWhere()
      if (!params) return false
      this.loading.planSourceCount = true
      statisticsCount(params).then(res => {
        if (res.data.state) {
          const {data} = res.data;
          const _this = this;
          let keys = [];
          let seriesData = [];
          this.data.count = data;
          data.map((item) => {
            keys.push(item.key)
            seriesData.push(item.count)
          })
          let option = {
            color: ['#3398DB'],
            tooltip : {
              trigger: 'axis',
              axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis : [
              {
                type : 'category',
                data : keys,
                axisTick: {
                  alignWithLabel: true
                }
              }
            ],
            yAxis : [
              {
                type : 'value'
              }
            ],
            series : [
              {
                name:'信息总量',
                type:'bar',
                barWidth: '8%',
                data: seriesData
              }
            ]
          };
          this.$nextTick(() => {
            let element = window.document.getElementById('plan_source_count')
            if (!element) return false
            let myChart = globalCreateChart(element, option, true)
            myChart.resize();
            myChart.off("click")
            myChart.on("click", function (e) {
              const exps = _this.plan.exps.map((exp) => {
                return `${sourceName(exp)}(${exp.category})`;
              });
              const expId = exps.indexOf(e.name);
              if (expId !== -1) {
                _this.list({source_focus: [expId]})
              }
            })
            window.addEventListener("resize", () => { myChart.resize();})
          })
        } else {
          this.$message.error(res.data.error)
        }
        this.loading.planSourceCount = false
        if (resolve) resolve("planSourceCount")
      }).catch(err => {
        window.console.error(err);
        if (resolve) resolve("planSourceCount")
      })
    },
    planSourceSummary(resolve = null) {
      const params = this.calculationWhere()
      if (!params) return false
      this.loading.planSourceSummary = true
      statisticsSummary(params).then(res => {
        if (res.data.state) {
          const { data } = res.data
          this.data.summary = data
        } else {
          this.$message.error(res.data.error)
        }
        this.loading.planSourceSummary = false
        if (resolve) resolve("planSourceSummary")
      }).catch(res => {
        if (resolve) resolve("planSourceSummary")
      })
    },
    planSourceCountStat(resolve = null, unit = 'hour') {
      const params = this.calculationWhere()
      if (!params) return false
      params.interval = unit
      this.loading.planSourceCountStat = true
      statisticsCountStat(params).then(res => {
        this.loading.planSourceCountStat = false
        if (resolve) resolve("planSourceCountStat")
        if (res.data.state) {
          let seriesData = []
          const { data } = res.data
          const _this = this;

          this.data.countStat = data.keys
          data.legends.map((legend, index) => {
            seriesData.push({name: legend, type: "line", data: data.count[index]})
          })
          let option = {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              top:"10px",
              data: data.legends,
              type: 'scroll',
              itemWidth:10,
              itemHeight:10,
              orient: 'horizontal',
              // left:0,
              // right:0,
              icon:"circle",
              itemGap:10,
              pageIconColor:"#555DFE"
            },
            dataZoom: [
              {
                type: 'inside',
                start: 0,
                end: 100
              }
            ],
            grid: {
              left: '3%',
              right: '4%',
              top: '60px',
              width:"90%",
              bottom: '0',
              containLabel: true
            },
            toolbox: {
              show: false,
              feature: {
                magicType: {type: ['line', 'bar']}
              },
              right:"24px",
              top:"10px"
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: data.keys
            },
            yAxis: {
              type: 'value'
            },
            series: seriesData
          }
          this.$nextTick(() => {
            let element = window.document.getElementById('plan_source_count_stat')
            if (!element) return false
            let myChart = globalCreateChart(element, option, true)
            myChart.resize();
            window.addEventListener("resize", () => { myChart.resize();})
            myChart.off("click")
            myChart.on("click", function (e) {
              let baseMinDate = new Date(window.$moment(_this.where.dateSpan[0]).valueOf());
              let baseMaxDate = new Date(window.$moment(_this.where.dateSpan[1]).valueOf());
              let date = new Date(e.name);
              let begin = globalFormatDate(date);
              switch (unit) {
                case "hour":
                  date.setHours(date.getHours() + 1)
                  break;
                case "day":
                  date.setHours(23)
                  date.setMinutes(59)
                  date.setSeconds(59)
                  // 最大区间
                  date = date.getTime() > baseMaxDate ? baseMaxDate : date
                  break;
                case "month":
                  let days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
                  // 最小区间
                  begin = baseMinDate.getTime() > date.getTime() ? globalFormatDate(baseMinDate) : begin
                  date.setDate(days)
                  date.setHours(23)
                  date.setMinutes(59)
                  date.setSeconds(59)
                  // 最大区间
                  date = date.getTime() > baseMaxDate ? baseMaxDate : date
                  break;
              }
              let end = globalFormatDate(date);
              const exps = _this.plan.exps.map((exp) => {
                return `${sourceName(exp)}(${exp.category})`;
              });
              const expId = exps.indexOf(e.seriesName);
              if (expId !== -1) {
                _this.list({source_focus: [expId], span: `${begin}~${end}`});
              }
            });
          })
        } else {
          this.$message.error(res.data.error)
        }
      }).catch(res => {
        if (resolve) resolve("planSourceCountStat")
      })
    },
    planSourceCountStatNeg(resolve = null, unit = 'hour') {
      const params = this.calculationWhere()
      if (!params) return false
      params.interval = unit
      this.loading.planSourceCountStatNeg = true
      statisticsCountStatNeg(params).then(res => {
        this.loading.planSourceCountStatNeg = false
        if (resolve) resolve("planSourceCountStatNeg")
        if (res.data.state) {
          let seriesData = [];
          const {data} = res.data;
          const _this = this;
          this.data.countStatNeg = data.keys;
          data.legends.map((legend, index) => {
            seriesData.push({name: legend, type: "line", data: data.count[index]})
          });
          let option = {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              top:"10px",
              data: data.legends,
              type: 'scroll',
              itemWidth:10,
              itemHeight:10,
              itemGap:10,
              orient: 'horizontal',
              // left:0,
              // right:0,
              icon:"circle",
              pageIconColor:"#555DFE"
            },
            dataZoom: [
              {
                type: 'inside',
                start: 0,
                end: 100
              }
            ],
            grid: {
              left: '3%',
              right: '4%',
              top: '60px',
              width:"90%",
              bottom: '0',
              containLabel: true
            },
            toolbox: {
              show: false,
              feature: {
                magicType: {type: ['line', 'bar']}
              },
              right:"24px",
              top:"10px"
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: data.keys
            },
            yAxis: {
              type: 'value'
            },
            series: seriesData
          }
          this.$nextTick(() => {
            let element = window.document.getElementById('plan_source_count_stat_neg')
            if (!element) return false
            let myChart = globalCreateChart(element, option, true)
            myChart.resize();
            window.addEventListener("resize", () => { myChart.resize();})
            myChart.off("click")
            myChart.on("click", function (e) {
              let baseMinDate = new Date(window.$moment(_this.where.dateSpan[0]).valueOf());
              let baseMaxDate = new Date(window.$moment(_this.where.dateSpan[1]).valueOf());
              let date = new Date(window.$moment(e.name).valueOf());
              let begin = globalFormatDate(date);
              switch (unit) {
                case "hour":
                  date.setHours(date.getHours() + 1)
                  break;
                case "day":
                  date.setHours(23)
                  date.setMinutes(59)
                  date.setSeconds(59)
                  // 最大区间
                  date = date.getTime() > baseMaxDate ? baseMaxDate : date
                  break;
                case "month":
                  let days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
                  // 最小区间
                  begin = baseMinDate.getTime() > date.getTime() ? globalFormatDate(baseMinDate) : begin
                  date.setDate(days)
                  date.setHours(23)
                  date.setMinutes(59)
                  date.setSeconds(59)
                  // 最大区间
                  date = date.getTime() > baseMaxDate ? baseMaxDate : date
                  break;
              }
              let end = globalFormatDate(date);
              const exps = _this.plan.exps.map((exp) => {
                return `${sourceName(exp)}(${exp.category})`;
              });
              const expId = exps.indexOf(e.seriesName);
              if (expId !== -1) {
                _this.list({source_focus: [expId], span: `${begin}~${end}`, emotion: 'negative'});
              }
            });
          })
        } else {
          this.$message.error(res.data.error)
        }
      }).catch(res => {
        if (resolve) resolve("planSourceCountStatNeg")
      })
    },
    planSourceCountStatPercent(resolve = null) {
      let params = this.calculationWhere()
      if (!params) return false
      this.loading.planSourceCountStatPercent = true
      statisticsCountStatPercent(params).then(res => {
        if (res.data.state) {
          const { data } = res.data
          const _this = this
          let legend = []
          let seriesData = []
          let selected = {}
          this.data.countStatPercent = _.orderBy(data, ['count'], ['desc'])
          this.data.countStatPercent.map((item, index) => {
            selected[item.key] = index < 1 ? true : false
            legend.push(item.key)
            seriesData.push({ name: item.key, value: item.count })
          })
          let option = {
            tooltip: {
              trigger: 'item',
              formatter: "{a} <br/>{b}: {c}({d}%)"
            },
            toolbox: {
              show: true,
              feature: {},
              right:"3%",
              top:"10px"
            },
            legend: {
              type: 'scroll',
              orient: 'horizontal',
              data: legend,
              top: "10px",
              itemWidth:10,
              itemHeight:10,
              itemGap:10,
              // right:"0",
              // left:"0",
              icon: "circle",
              selected: selected,
              pageIconColor:"#555DFE"
            },
            // color: ['#fd5066', '#009df6'],
            series: [
              {
                name:'声量占比',
                type:'pie',
                center:['50%', '45%'],
                radius: ['40%', '50%'],
                padding: [5, 0],
                avoidLabelOverlap: false,
                data: seriesData
              }
            ]
          }
          this.$nextTick(() => {
            let element = window.document.getElementById('plan_source_count_stat_percent')
            if (!element) return false
            let myChart = globalCreateChart(element, option, true)
            myChart.resize();
            window.addEventListener("resize", () => { myChart.resize();})
            myChart.off("click")
            myChart.on("click", function (e) {
              const exps = _this.plan.exps.map((exp) => {
                return `${sourceName(exp)}(${exp.category})`;
              });
              const expId = exps.indexOf(e.name);
              if (expId !== -1) {
                _this.list({source_focus: [expId]})
              }
            });
          })
        } else {
          this.$message.error(res.data.error)
        }
        this.loading.planSourceCountStatPercent = false
        if (resolve) resolve("planSourceCountStatPercent")
      }).catch(res => {
        if (resolve) resolve("planSourceCountStatPercent")
      })
    },
    planSourceCountStatDistributed(resolve = null) {
      let params = this.calculationWhere()
      if (!params) return false
      this.loading.planSourceCountStatDistributed = true
      statisticsCountStatDistributed(params).then(res => {
        if (res.data.state) {
          const { data } = res.data
          const _this = this
          let keys = _.keys(data)
          let positiveData = []
          let negativeData = []
          this.data.countStatDistributed = keys
          keys.map((item) => {
            let total = parseInt(data[item].pos) + parseInt(data[item].neg)
            let pos = (parseInt(data[item].pos) / total * 100).toFixed(0)
            let neg = (parseInt(data[item].neg) / total * 100).toFixed(0)
            pos = isNaN(pos) ?  0 : pos
            neg = isNaN(neg) ?  0 : neg
            positiveData.push(pos)
            negativeData.push(neg)
          })
          let option = {
            tooltip : {
              trigger: 'axis',
              axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: "{b} <br />{a}：{c}<br />{a1}：{c1}"
            },
            legend: {
              data: ['负面','正面'],
              icon: "circle",
              itemGap:20,
              itemWidth:10,
              itemHeight:10,
              top:"10px",
              selectedMode: false
            },
            grid: {
              left: '3%',
              right: '4%',
              top: '60px',
              bottom:"10px",
              containLabel: true
            },
            xAxis:  {
              type: 'value',
              axisLabel: {
                show: true,
                interval: 'auto',
                formatter: '{value}%'
              }
            },
            yAxis: {
              type: 'category',
              data: keys
            },
            series: [
              {
                name: '负面',
                type: 'bar',
                stack: '总量',
                itemStyle: {
                  normal: {
                    color: "#FC5D73",
                    barBorderRadius:[10, 0, 0, 10]
                  }
                },
                data: negativeData
              },
              {
                name: '正面',
                type: 'bar',
                stack: '总量',
                barWidth: 10,
                itemStyle: {
                  normal: {
                    color: "#555DFE",
                    barBorderRadius:[0, 10, 10, 0]
                  }
                },
                data: positiveData
              }
            ]
          }
          this.$nextTick(() => {
            let element = window.document.getElementById('plan_source_count_stat_distributed')
            if (!element) return false
            let myChart = globalCreateChart(element, option, true)
            myChart.resize();
            window.addEventListener("resize", () => { myChart.resize();})
            myChart.off("click")
            myChart.on("click", function (e) {
              const exps = _this.plan.exps.map((exp) => {
                return `${sourceName(exp)}(${exp.category})`;
              });
              const expId = exps.indexOf(e.name);
              if (expId !== -1) {
                _this.list({source_focus: [expId], emotion: e.seriesName === '正面' ? 'positive' : 'negative'})
              }
            });
          })
        } else {
          this.$message.error(res.data.error)
        }
        this.loading.planSourceCountStatDistributed = false
        if (resolve) resolve("planSourceCountStatDistributed")
      }).catch(res => {
        if (resolve) resolve("planSourceCountStatDistributed")
      })
    },
    planSourceWords(resolve = null, source = null) {
      let params = this.calculationWhere()
      if (!params) return false
      if (source) {
        params['source_focus_data'] = source
      }
      this.loading.planSourceWords = true
      const colors = ['#555DFE', '#4E89FE', '#8CB6FF', '#A5A5A5']
      statisticsWords(params).then(res => {
        if (res.data.state) {
          const {data} = res.data;
          const _this = this;
          let seriesData = []
          this.data.sourceWords = data
          data.map((item, index) => {
            let colorIndex = Math.floor((Math.random() * colors.length))
            seriesData.push({ name: item.key, value: item.count, textStyle: { color: colors[colorIndex] } })
          })
          let option = {
            tooltip: {
              trigger: 'item'
            },
            toolbox: {
              feature: {},
              right:"3%",
              top:"10px"
            },
            series: [{
              name: '信息数',
              type: 'wordCloud',
              shape: 'circle', // circle cardioid diamond square triangle-forward triangle
              left: 'center',
              top: '10%',
              width: '60%',
              height: '70%',
              right: null,
              bottom: null,
              sizeRange: [10, 48],
              rotationRange: [0, 0],
              rotationStep: 90,
              hape: 'pentagon',
              gridSize: 15,
              drawOutOfBound: false,
              textStyle: {
                normal: {
                  fontFamily: 'sans-serif',
                  //fontWeight: 'bold'
                },
                emphasis: {
                  shadowBlur: 10,
                  shadowColor: '#333'
                }
              },
              data: seriesData
            }]
          }
          var maskImage = new Image();
          //重点：云彩图片的base64码
          maskImage.src = require('@/assets/images/common/ciyun.jpg')
          maskImage.onload = function() {
            // plan_source_words
            // option.series[0].maskImage = maskImage
            let element = window.document.getElementById('plan_source_words')
            // const wd = _.size(seriesData) / 100 * 70
            // const wwd = wd < 50 ? 50 : wd
            // element.style.width = `${wwd}%`
            // element.style.margin = '0 auto'

            if (!element) return false
            _this.$nextTick(() => {
              option.series[0].maskImage = maskImage
              let myChart = globalCreateChart(element, option, true)
              myChart.resize()
              window.addEventListener("resize", () => { myChart.resize();})
              myChart.off("click")
              myChart.on("click", function (e) {
                if (source) {
                  let s = JSON.parse(source)
                  const exps = _this.plan.exps.map((exp) => {
                    return `${sourceName(exp)}(${exp.category})`;
                  });
                  const expId = exps.indexOf(`${sourceName(s)}(${s.category})`);
                  if (expId !== -1) {
                    _this.list({source_focus: [expId], focus: {"op":"and","must": e.name,"not":""}})
                  }
                } else {
                  _this.list({focus: {"op":"and","must": e.name,"not":""}})
                }
              });
            })
          }
        } else {
          this.$message.error(res.data.error)
        }
        this.loading.planSourceWords = false
        if (resolve) resolve("planSourceWords")
      }).catch(res => {
        if (resolve) resolve("planSourceWords")
      })
    },
    planSourceArticles(resolve = null, source = null) {
      let params = this.calculationWhere()
      if (!params) return false
      if (source) {
        params['source_focus_data'] = source
      }
      this.loading.planSourceArticles = true
      statisticsArticles(params).then(res => {
        if (res.data.state) {
          const { data } = res.data
          this.data.articles = data
        } else {
          this.$message.error(res.data.error)
        }
        this.loading.planSourceArticles = false
        if (resolve) resolve("planSourceArticles")
      }).catch(res => {
        if (resolve) resolve("planSourceArticles")
      })
    },
    planSourceAccount(resolve = null, source = null) {
      const params = this.calculationWhere()
      if (!params) return false
      if (source) {
        params['source_focus_data'] = source
      } else {
        params['source_focus_data'] = _.first(this.plan.exps)
      }
      this.loading.planSourceAccount = true
      statisticsRelationAccount(params).then(res => {
        if (res.data.state) {
          const { data } = res.data
          this.data.account = data
        } else {
          this.$message.error(res.data.error)
        }
        this.loading.planSourceAccount = false
        if (resolve) resolve()
      }).catch(res => {
        if (resolve) resolve()
      })
    },
    planSourceEntity(resolve = null, source = null) {
      let params = this.calculationWhere()
      if (!params) return false
      if (source) {
        params['source_focus_data'] = source
      }
      this.loading.planSourceEntity = true
      statisticsEntity(params).then(res => {
        this.loading.planSourceEntity = false
        if (resolve) resolve()
        if (res.data.state) {
          const _this = this
          const { data, key } = res.data.data
          const id = 'source-entity'
          this.data.entity = data
          if (Array.prototype.isPrototypeOf(data) && (data.length < 1)) return false
          let maxr = data.sort((a, b) => { return b.count - a.count })[0].count
          function randomColor() {
            // Random color
            let color = ['#F31914', '#469C1D', '#FAB78C', '#FAB78C', '#838383', '#3F6BA5', '#E21989']
            let index = Math.floor((Math.random()*color.length))
            return color[index]
          }
          function Bubble(option){
            var _defaultOption = {
              width:488,
              height:340,
              padding:1.5,
              data:'',
              conEle:''
            };
            option = $.extend(true, _defaultOption,option);
            this.width  = option.width;
            this.height = option.height;
            this.padding= option.padding;
            this.data   = option.data;//数据url或对象,必填
            this.conEle = option.conEle;//svg容器(node或者选择器)，必填
            this.mouseenter = function(d,node){}
            this.mouseleave = function(d,node){}
            this.click = function (d) {}
          }
          Bubble.prototype.init = function(){
            var that = this,
                    //1.设置颜色
                    color = d3.scale.category20c(),
                    //2.布局
                    bubble = d3.layout.pack()
                            .sort(null)
                            .size([that.width,that.height])
                            .radius((d) => {
                              let val = d / maxr * 30
                              return val < 10 ? 10 : val
                            })
                            .padding(that.padding),
                    //3.添加svg元素
                    svg = d3.select(that.conEle).append("svg")
                            .attr("width", that.width)
                            .attr("font-size", '12')
                            .attr("height", that.height);
            //4.数据请求及图形绘制
            if(typeStr(that.data)=='[object string]'){
              d3.json(that.data,function(error,data){
                if(error) throw error;
                //1.对数据进行处理
                data = dataHandle(data);
                render(svg,bubble,that,data);
              })
            }else{
              render(svg,bubble,that,dataHandle(that.data));
            }
          }
          function typeStr(obj){
            return Object.prototype.toString.call(obj).toLowerCase();
          }
          //Returns a flattened hierarchy containing all leaf nodes under the root.
          function classes(root){
            var classes = [];                                                                                        //存储结果的数组
            /*
             * 自定义递归函数
             * 第二个参数指传入的json对象
             */
            function recurse(name, node) {
              if (node.children)                                                                                   //如果有孩子结点 （这里的children不是自带的，是json里面有的）
              {
                node.children.forEach(function(child) {                                                          //将孩子结点中的每条数据
                  recurse(node.name, child);
                })
              }
              else {
                //如果自身是孩子结点的将内容压入数组
                classes.push({ name: node.name, value: node.size,props:node.props})
              };
            }
            recurse(null, root);
            return {children: classes};
          }
          function render(view,layout,context,data,cb){
            var node = view.selectAll(".node")
                    //绑定数据（配置结点）
                            .data(layout.nodes(classes(data))
                                    .filter(function(d) {
                                      //数据过滤，满足条件返回自身（没孩子返回自身，有孩子不返回，这里目的是去除父节点）
                                      return !d.children;
                                    }))
                            .enter().append("g")
                            .attr("class", "node")
                            .attr("transform", function(d) {
                              //设定g移动
                              return "translate(" + d.x + "," + d.y + ")";
                            }),
                    usingNodes = node.filter(function(d){
                      return d.props.using;
                    }),
                    time = +new Date(),
                    duration = 1000,
                    strokeWidth = 0;
            node.append("circle")
                    .attr("r", function(d) {
                      //设置圆的半径
                      return d.r;
                    })
                    .style("fill", function(d) {
                      //气泡颜色
                      return d.props.color;
                    })
                    .style("fill-opacity",1);
            node.append("text")
                    .attr("dy", ".3em")
                    //设置文本对齐
                    .style("text-anchor", "middle")
                    .style("font-size",'10px')
                    .style("font-weight",'800')
                    .style("fill", function (d) {
                      //字体颜色
                      return randomColor()
                    })
                    //根据半径的大小来截取对应长度字符串(很重要)
                    .text(function(d) {
                      return d.name.substring(0, d.r / 5);
                    });
            function animate(){
              var nowTime = +new Date();
              if((nowTime-duration) > time) {
                time = nowTime;
                strokeWidth = 0;
              }
              strokeWidth += 0.6;
              //strokeWidth >10?strokeWidth=10:strokeWidth += 1;
              usingNodes.select("circle")
                      .style("stroke-width",strokeWidth+'px')
                      .style("stroke-opacity",'0.3')
                      .style("stroke",function(d){
                        return d.props.color;
                      });
              requestAnimationFrame(animate);
            }
            animate();
            node.on('mouseenter',function(d){
              var node = this;
              context.mouseenter(d,node);
            })
            node.on('mouseleave',function(d){
              var node = this;
              context.mouseleave(d,node);
            })
            node.on('click', function (d) {
              var node = this;
              context.click(d)
            })
          }
          //定义数据处理方法
          function dataHandle(data){
            var result = {
              name:"flare",
              children:[]
            }
            data.forEach(function(ele){
              result.children.push({
                name:ele.name,
                size:ele.value,
                props:ele.props
              });
            });
            return result;
          }
          function createInfoTip(d){
            var html = '<div class="node-info fz-12"><ul>';
            html += '<li class="info-title"><span>'+d.name+'</span></li>';
            html += '<li class="info-content"><i class="bg-normal"></i><span class="info-content-label">信息数'+
                    '</span><span class="info-content-text">'+d.value+'</span></li>';
            html += '</ul></div>';
            return html;
          }
          // 清除旧数据
          window.jQuery(`#${id}`).children().remove()
          d3.select(`#${id}`)
                  .selectAll('*')
                  .remove();
          var chartData = [];
          _(data).forEach((item, i) => {
            chartData.push({
              name: item.key,
              value: item.count,
              props: {
                abnormal: false,
                color: '#F4F4F4',
                using: false
              }
            })
          })
          this.$nextTick(() => {
            let element = window.document.getElementById(id)
            if (!element) return false // 切换时直接break
            var option = {
              data: chartData,
              conEle:`#${id}`,
              width: $(`#${id}`).width(),
              height:340,
              padding:2
            }
            var bubble = new Bubble(option)
            bubble.click = function (d) {
              // TODO:: 列表点击事件
              if (source) {
                let s = JSON.parse(source)
                const exps = _this.plan.exps.map((exp) => {
                  return `${sourceName(exp)}(${exp.category})`;
                });
                const expId = exps.indexOf(`${sourceName(s)}(${s.category})`);
                if (expId !== -1) {
                  _this.list({source_focus: [expId], focus: {"op":"and","must": d.name,"not":""}})
                }
              } else {
                _this.list({focus: {"op":"and","must": d.name,"not":""}})
              }
            }
            bubble.mouseenter = function(d,node){
              var $con = window.$(`#${id}`);
              var rectBox = $con[0].getBoundingClientRect();
              d3.select(node).style("cursor","pointer");
              $con.append(createInfoTip(d));
              window.$(".node-info").css({
                left: d3.event.x+20-rectBox.left,
                top: d3.event.y+20-rectBox.top
              }).show();
            }
            bubble.mouseleave = function(d){
              window.$(".node-info").remove();
            }
            bubble.init();
          })
          this.planSourceRelationEvent(null, key);
        } else {
          this.$message.error(res.data.error);
        }
      }).catch(() => {
        if (resolve) resolve();
      })
    },
    planSourceRelationEvent(resolve = null, key) {
      let params = this.calculationWhere()
      if (!params) return false
      params['key'] = key
      this.loading.planSourceRelationEvent = true
      statisticsRelationEvent(params).then(res => {
        if (res.data.state) {
          const { data } = res.data
          this.data.relationEvent = data.nodes
          let nodes = _.uniqBy(data.nodes, 'id')
          let links = _.uniqBy(data.links, 'id')
          let _this = this
          let option = {
            tooltip: {},
            legend: [],
            animationDurationUpdate: 1500,
            animationEasingUpdate: 'quinticInOut',
            series : [
              {
                name: '',
                type: 'graph',
                layout: 'circular',
                circular: {
                  rotateLabel: true
                },
                data: nodes,
                links: links,
                categories: [],
                roam: true,
                label: {
                  normal: {
                    position: 'right',
                    formatter: '{b}'
                  }
                },
                lineStyle: {
                  normal: {
                    color: 'source',
                    curveness: 0.3
                  }
                }
              }
            ]
          };
          this.$nextTick(() => {
            let element = window.document.getElementById('source-relation-event');
            if (!element) return false;
            let myChart = globalCreateChart(element, option, true);
            myChart.off("click");
            myChart.on("click", function (e) {
              if (e.data.hasOwnProperty('event_id')) {
                const {event_id, name} = e.data;
                _this.$refs['infor'].requestListData(`${window.service.api}/message/event_info`, {event_id, name});
              }
            })
            myChart.resize();
            window.addEventListener("resize", () => {
              myChart.resize();
            });
          })
        } else {
          this.$message.error(res.data.error);
        }
        this.loading.planSourceRelationEvent = false;
        if (resolve) resolve();
      }).catch(() => {
        if (resolve) resolve();
      })
    },
    toPlan() {
      this.$emit('changeIndex', 'plan');
    },
  }
};
</script>
<style scoped>
.staatistics-cont .mar-in {height: 20px;background-color: #FFF;box-shadow: 0px 20px 0px 0px rgba(65, 70, 76, 0.02)}
.staatistics-cont .mod-list li{height: 30px;line-height: 30px;}
.staatistics-cont .mod-list .num{margin-top: 6px;}
.staatistics-cont .echarts_empty,.staatistics-cont .mod-echarts-cont{height: 340px;}
.staatistics-cont .yq-form .mod-title{margin-left: 0;padding-left: 0;border:none;}
.staatistics-cont .yq-form .mod-title span:first-child{padding-left:20px;float:left;margin-right: 10px;margin-top:8px;}
.source-statistics .mod-title{margin-left: 0;}
.source-statistics >>> .el-card__body{padding:20px;}
/* 信源概览 */
.xygl .tag,.xgzh .tag{overflow: hidden;white-space: nowrap;text-overflow:ellipsis;width:120px;}
.xygl .mod-list-title1{width:300px;}
/* 信源关键文章 */
.xygjwz .mod-list-title2{width:63%;}
.xygjwz .tag{width: 12%;}
/* 相关账号 */
.xgzh .mod-list-title2{width: 50%;}
/*2.0.6迭代 表单 */
.demo-form-inline .form-box{background:none;padding:0;}
.yq-form-box{padding:0 40px;}
.demo-form-inline >>> .el-form-item__label{font-weight: 600;}
.demo-form-inline .hr{height:12px;position: relative;width:100%;}
.demo-form-inline .hr .line{height:1px;background:#EBECF0;width:100%;position: absolute;left:0;top:6px;}
.demo-form-inline .hr .iconfont{position: absolute;left:calc(50% - 5px);top:0;z-index:5;padding:0 5px;background:#fff;cursor: pointer;}
.demo-form-inline >>> .w-50{width:50%;display:inline-block;}
.demo-form-inline >>> .el-radio{margin-right: 25px;}
</style>
<style>
  #statistics-card {
    box-shadow: inset 0 15px 15px -15px rgba(65, 70, 76, 0.07);
    background-color: transparent;
  }
</style>
