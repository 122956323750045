<template>
  <el-container class="information-cont">
    <div class="w-100">
      <el-row class="empty" v-if="!loading.plans && plans.length < 1">
        <div>
          <img
            src="@/assets/images/common/empty/xy_fagl.svg"
            alt="信息列表"
            width="400"
            class="img"
            type="image/svg+xml"
          />
          <p>
            <span>空空的，快来新增信源信息吧</span>
            <el-button type="primary" round @click="toPlan()"
              >新增信源方案</el-button
            >
          </p>
        </div>
      </el-row>
      <div v-loading="loading.plans" v-else>
        <!-- 表单 -->
        <el-card
          class="box-card yq-form demo-form-inline"
          id="information-card"
        >
          <el-form
            ref="form"
            label-width="100px"
            label-position="left"
            class="form-box"
          >
            <el-form-item label="选择方案">
              <el-select
                v-model="where.plan_id"
                placeholder="请选择方案"
                class="w-160 mar-r-20"
                @change="planChange()"
                :disabled="loading.main"
              >
                <el-option
                  v-for="(plan, index) in plans"
                  :key="index"
                  :value="plan.pid"
                  :label="plan.name"
                >
                  <span>{{ plan.name }}</span>
                  <i
                    class="iconfont icon-bianji f-r"
                    @click="editedPlan(plan.pid)"
                    v-if="user.isadmin || user.id === plan.createdby"
                  ></i>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分析时间" class="w-50">
              <el-date-picker
                :disabled="loading.main"
                v-model="where.dateSpan"
                @change="dateSpanChange"
                :picker-options="{
                  disabledDate: dateRange,
                  shortcuts: pickerOptions.shortcuts
                }"
                :editable="false"
                :clearable="false"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetimerange"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="排序方式" class="w-50">
              <el-select
                v-model="where.order"
                placeholder="请选择"
                class="w-200"
              >
                <el-option
                  v-for="(item, index) in orderOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="相似合并" class="w-50">
              <el-radio-group v-model="where.similar">
                <el-radio label="no">不合并</el-radio>
                <el-radio label="yes">合并</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="情感属性" class="w-50">
              <el-radio-group v-model="where.emotion">
                <el-radio label="all">全部</el-radio>
                <el-radio label="negative">敏感</el-radio>
                <el-radio label="positive">非敏感</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="来源再锁定">
              <el-radio-group v-model="where.allow_source_focus">
                <el-radio :label="false">不锁定</el-radio>
                <el-radio :label="true">锁定</el-radio>
              </el-radio-group>
              <el-select
                v-model="where.source_focus"
                multiple
                collapse-tags
                placeholder="选择锁定源"
                v-show="where.allow_source_focus"
                class="w-280 mar-l-10"
              >
                <el-option
                  v-for="(item, index) in plan.exps"
                  :value="index"
                  :label="getName(item)"
                  :key="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form
            ref="form"
            label-width="100px"
            label-position="left"
            class="form-box"
            v-show="filter"
          >
            <el-form-item label="信息地区" class="w-50">
              <el-radio-group v-model="where.area">
                <el-radio label="all">全部</el-radio>
                <el-radio label="cn">境内</el-radio>
                <el-radio label="other">境外</el-radio>
                <el-radio label="customer">自定义</el-radio>
              </el-radio-group>
              <el-cascader
                  v-model="customerArea"
                  :options="provinceOptions"
                  @change="handleCityChange"
                  v-if="where.area === 'customer'" />
            </el-form-item>
            <el-form-item label="语言" class="w-50">
              <el-radio-group v-model="where.lang">
                <el-radio label="all">全部</el-radio>
                <el-radio label="zh">中文</el-radio>
                <el-radio label="en">其他</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div class="hr mar-t-20">
            <span class="line"></span>
            <i
              class="iconfont"
              @click="filter = !filter"
              :class="filter ? 'icon-xiangshang' : 'icon-xiangxia'"
            ></i>
          </div>
          <div class="flex mtlx mar-t-20">
            <span class="mar-r-20 fz-12 b">媒体类型</span>
            <div class="flex-1">
              <div class="flex">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="mediaCheckAll"
                  @change="handleCheckAll"
                >
                  {{ mediaFormat('全部') }}
                </el-checkbox>
                <el-checkbox-group
                  v-model="where.media"
                  @change="handleChange"
                  class="flex-1"
                >
                  <el-checkbox
                    v-for="(media, index) in medias"
                    :label="media"
                    :key="index"
                  >
                    {{ mediaFormat(media) }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="button-group mar-t-20">
            <el-button
              round
              type="primary"
              @click="loadData"
              :disabled="loading.main"
              v-loading="loading.main"
              >查询</el-button
            >
            <el-button round @click="initWhere">重置</el-button>
          </div>

          <el-form
            ref="form"
            label-width="100px"
            label-position="left"
            class="form-box hide"
            v-show="filter"
          >
            <el-form-item label="关键词锁定">
              <div>
                <el-input
                  placeholder="多个关键词请用英文逗号隔开"
                  class="w-280 mar-r-20"
                  v-model="where.focus.must"
                ></el-input>
                <span>关键词关系：</span>
                <el-radio-group class="yhh" v-model="where.focus.op">
                  <el-radio label="and">与</el-radio>
                  <el-radio label="or">或</el-radio>
                </el-radio-group>
              </div>
              <div class="mar-t-10">
                <span class="mar-r-10">排除</span>
                <el-input
                  placeholder="多个关键词请用英文逗号隔开"
                  class="w-246"
                  v-model="where.focus.not"
                ></el-input>
              </div>
            </el-form-item>
          </el-form>
        </el-card>
        <!--@end 表单 -->
        <div class="mar-in"></div>
        <el-card class="box-card mar-t-20 xxlb">
          <div class="mod-title">信息列表</div>
          <div class="box-shadow"></div>
          <xxlb
            ref="list"
            :orderOptions="orderOptions"
            @exportData="exportData"
            @done="loadDataDone"
          />
        </el-card>
      </div>
      <article-detail ref="detail" :materials="materials" />
    </div>
  </el-container>
</template>
<script>
import articleDetail from '@components/common/article-detail.vue';
import xxlb from '@components/common/list.vue';
import {
  dateRangeDisabled,
  sourceFeature,
  sourceName,
  globalFormatDate,
  getAuthToken,
  queryString,
  numberFormat,
  handleDownload
} from '@/utils/helpers.js';
import {
  orderOptions,
  pickerOptions,
  medias,
  province,
  provinceOptions
} from '@/utils/constants';
import { lists as materialList } from '@/api/material';
import { getPlans as sourcePlans } from '@/api/plan';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      orderOptions: orderOptions,
      isIndeterminate: false,
      pickerOptions,
      provinceOptions,
      plans: [],
      plan: { exps: [] }, // 当前选中方案
      customerArea: [], // 自定义区域
      where: {
        plan_id: null,
        dateSpanString: '',
        dateSpan: null,
        emotion: 'all',
        focus: { op: 'and', must: '', not: '' },
        area: 'all',
        area_cn: '北京',
        city: '',
        lang: 'all',
        media: [],
        source_focus: [],
        allow_source_focus: false,
        similar: 'no',
        order: 'intelligence'
      },
      filter: false,
      medias: medias,
      province: province,
      mediaCheckAll: true,
      loading: { plans: false, main: false },
      data: [],
      statistics: null,
      checkedData: {},
      checkedAll: false,
      checkbox: false,
      joinMaterial: { visible: false, selectedId: '', loading: false },
      materials: [],
      currentPage: 1,
      currentPageSize: 20,
      total: 0,
      pageTotal: 0 // 可查看总数
    };
  },
  components: {
    articleDetail,
    xxlb
  },
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    'where.plan_id': function(pid) {
      this.$store.commit('SET_SOURCE_FILTER', {
        key: 'plan_id',
        value: pid
      });
      this.handleSourceFocus(pid);
    }
  },
  mounted() {
    this.getPlans();
    this.loadMaterial();
  },
  methods: {
    planChange() {
      this.where.focus = { op: 'and', must: '', not: '' };
    },
    handleCityChange(v) {
      if (v && v.length >= 2) {
        Object.assign(this.where, {
          area_cn: v[0],
          city: v[0] !== v[1] ? v[1] : ''
        })
      }
    },
    loadMaterial() {
      materialList()
        .then(res => {
          if (res.data.state) {
            var result = res.data.data;
            this.materials = result;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error('加载收藏夹失败，服务错误');
        });
    },
    editedPlan(pid) {
      this.$emit('toEditedPlan', pid);
    },
    getPlans() {
      this.loading.plans = true;
      sourcePlans({ enable: 'enable', type: 'ids' }).then(res => {
        if (res.data.state) {
          const { data } = res.data;
          this.plans = data.filter(plan => {
            // 过滤没有信源的 或 没有审核通过信源的
            let exps = _.isArray(plan.exps) ? plan.exps : JSON.parse(plan.exps);
            let succExps = exps.filter(exp => {
              return exp.state === 'succ';
            });
            return _.size(succExps) >= 1;
          });
          // 初始化筛选条件
          if (_.size(this.plans) >= 1) {
            this.initWhere();
          }
        } else {
          this.$message.error(res.data.error);
        }
        this.loading.plans = false;
      });
    },
    loadData() {
      let params = this.calculationWhere();
      if (!params) return false;

      this.loading.main = true;
      this.$refs.list.loadData(
        `${window.service.api}/plan/plan_source_msg`,
        params,
        false,
        'source'
      );
    },
    loadDataDone(data, params = {}) {
      if (data !== undefined) {
        this.statistics = data.statistics;
      }
      Object.assign(this.where, params);
      this.loading.main = false;
    },
    mediaFormat(media) {
      if (!this.statistics) {
        return `${media}(0)`;
      }

      if (media === '全部') {
        let total = _.sumBy(this.statistics, 'num');
        return `全部(${numberFormat(total)})`;
      }

      let row = _.find(this.statistics, o => {
        return o.media === media;
      });
      let rowTotal = row === undefined ? 0 : numberFormat(row.num);

      return `${media}(${rowTotal})`;
    },
    initWhere() {
      let cachePid = this.$store.state.config.sourceFilter.plan_id;
      let cacheSpan = this.$store.state.config.sourceFilter.span;
      let pid = this.plans[0].pid;
      let span = null;
      if (cachePid) {
        let plan = _.find(this.plans, { pid: cachePid });
        if (plan !== undefined) {
          pid = cachePid;
        }
      }
      if (cacheSpan) {
        span = cacheSpan.split('~');
      } else {
        // 默认24小时
        const start = new Date();
        const end = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        let begin = globalFormatDate(start);
        let now = globalFormatDate(end);
        span = [begin, now];
      }
      this.$store.commit('SET_SOURCE_FILTER', {
        key: 'plan_id',
        value: pid
      });
      this.$store.commit('SET_SOURCE_FILTER', {
        key: 'span',
        value: span.join('~')
      });
      Object.assign(this.where, {
        plan_id: pid,
        dateSpan: span,
        dateSpanString: span.join('~'),
        media: this.medias,
        emotion: 'all',
        focus: { op: 'and', must: '', not: '' },
        similar: 'no',
        area: 'all',
        area_cn: '北京',
        lang: 'all',
        source_focus: [],
        allow_source_focus: false,
        order: 'intelligence'
      });
      this.currentPage = 1;
      this.currentPageSize = 20;
      this.mediaCheckAll = true;
      this.handleSourceFocus(this.plans[0].pid);
      this.loadData();
    },
    exportData(total) {
      let params = this.calculationWhere();
      if (!params) return false;
      params.op = 'down';
      params.token = getAuthToken();
      params.focus = JSON.stringify(params.focus);
      const query = decodeURIComponent(queryString(params, true));

      handleDownload(
        `${window.service.api}/message/excel${query}`,
        'GET',
        `${window.$moment().format('YYYY-MM-DD')}.xlsx`,
        null,
        total,
        () => {
          this.$refs['list'].exportLoading = false;
        }
      );
    },
    handleSourceFocus(pid) {
      this.plan = _.find(this.plans, ['pid', pid]);
      let exps = _.isArray(this.plan.exps)
        ? this.plan.exps
        : JSON.parse(this.plan.exps);
      // 只能匹配审核成功的信源
      this.plan.exps = exps.filter(exp => {
        return exp.state === 'succ';
      });
      this.where.source_focus = _.keys(this.plan.exps).map(n => {
        return parseInt(n);
      });
    },
    dateRange(date) {
      return dateRangeDisabled(date);
    },
    dateSpanChange(date) {
      if (date) {
        this.where.dateSpanString = `${date[0]}~${date[1]}`;
        this.$store.commit('SET_SOURCE_FILTER', {
          key: 'span',
          value: `${date[0]}~${date[1]}`
        });
      } else {
        this.where.dateSpanString = '';
      }
    },
    // 点击选择
    handleChange(value) {
      let checkedCount = value.length;
      this.mediaCheckAll = checkedCount === this.medias.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.medias.length;
    },
    // 全选按钮
    handleCheckAll(val) {
      // 将反选按钮重置
      this.where.media = val ? this.medias : [];
      this.isIndeterminate = false;
    },
    getName(row) {
      return `${sourceName(row)}(${row.category})`;
    },
    calculationWhere() {
      const { where, plan } = this;
      const params = {
        plan_id: where.plan_id,
        span: where.dateSpanString,
        emotion: where.emotion,
        lang: where.lang,
        media: where.media.join(','),
        area_cn: where.area === 'customer' ? where.area_cn : where.area,
        city: where.city,
        focus: where.focus,
        field: 'content',
        similar: where.similar,
        page: this.currentPage,
        size: this.currentPageSize,
        order: where.order
      };
      if (params.media.length < 1) {
        this.$message.warning('媒体类型不能为空');
        return false;
      }
      // 来源再次锁定
      if (where.allow_source_focus) {
        if (_.size(where.source_focus) < 1) {
          this.$message.warning('请选择要锁定的信源');
          return false;
        }
        params['source_focus'] = [];
        where.source_focus.map(index => {
          params['source_focus'].push(plan.exps[index]);
        });
        params['source_focus'] = JSON.stringify(params['source_focus']);
      }
      return params;
    },
    checkChange(checked, rowkey) {
      if (checked == false) {
        this.checkedAll = false;
      } else {
        // 判断全选状态
        let state = true;
        _(this.data).forEach((item, i) => {
          if (!item.checked) state = false;
        });
        this.checkedAll = state;
      }
      this.checkedData[rowkey] = checked;
    },
    handleSizeChange(size) {
      this.currentPageSize = size;
      this.currentPage = 1;
      this.loadData();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.loadData();
    },
    toPlan() {
      this.$emit('changeIndex', 'plan');
    }
  }
};
</script>
<style scoped>
.information-cont .mar-in {
  height: 20px;
  background-color: #fff;
  box-shadow: 0px 20px 0px 0px rgba(65, 70, 76, 0.02);
}
.information-cont .yq-form .mod-title {
  margin-left: 0;
  padding-left: 0;
  border: none;
}
.information-cont .yq-form .mod-title span:first-child {
  padding-left: 20px;
  float: left;
  margin-right: 10px;
  margin-top: 8px;
}
.information-cont >>> .el-card__body {
  padding: 20px 40px 30px;
}
/*2.0.6迭代 表单 */
.demo-form-inline .form-box {
  background: none;
  padding: 0;
}
.yq-form-box {
  padding: 0 40px;
}
.demo-form-inline >>> .el-form-item__label {
  font-weight: 600;
}
.demo-form-inline .hr {
  height: 12px;
  position: relative;
  width: 100%;
}
.demo-form-inline .hr .line {
  height: 1px;
  background: #ebecf0;
  width: 100%;
  position: absolute;
  left: 0;
  top: 6px;
}
.demo-form-inline .hr .iconfont {
  position: absolute;
  left: calc(50% - 5px);
  top: 0;
  z-index: 5;
  padding: 0 5px;
  background: #fff;
  cursor: pointer;
}
.demo-form-inline >>> .w-50 {
  width: 50%;
  display: inline-block;
}
.demo-form-inline >>> .el-radio {
  margin-right: 25px;
}
</style>
<style>
#information-card {
  box-shadow: inset 0 15px 15px -15px rgba(65, 70, 76, 0.07);
  background-color: transparent;
}
</style>
