<template>
  <el-container>
    <div class="w-100">
      <div class="content">
        <el-card class="box-card" id="source-card">
          <el-tabs v-model="activeIndex" @tab-click="handleClick" id="source-tabs">
            <el-tab-pane label="信源信息" name="information">
              <source-information v-if="activeIndex === 'information'" id="source-information" :activeIndex="activeIndex" v-on:toEditedPlan="toEditedPlan" v-on:changeIndex="changeIndex" />
            </el-tab-pane>
            <el-tab-pane label="可视化统计" name="statistics">
              <source-statistics v-if="activeIndex === 'statistics'" id="source-statistics" :activeIndex="activeIndex" v-on:toEditedPlan="toEditedPlan" v-on:changeIndex="changeIndex" />
            </el-tab-pane>
            <el-tab-pane label="信源方案管理" name="plan">
              <source-plan v-if="activeIndex === 'plan'" id="source-plans" :activeIndex="activeIndex" v-on:toEditedPlan="toEditedPlan" v-on:changeIndex="changeIndex" />
            </el-tab-pane>
            <el-tab-pane label="信源预警" name="caution">
              <source-caution v-if="activeIndex === 'caution'" id="source-caution" :activeIndex="activeIndex" v-on:toEditedPlan="toEditedPlan" v-on:changeIndex="changeIndex" />
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
    </div>
    <p class="jszc_text" v-if="version === 'shwj'">技术支持：上海索思数据科技有限公司 </p>
  </el-container>
</template>
<script type="application/javascript">
import Information from './tabs/information.vue'
import Statistics from './tabs/statistics.vue'
import Plan from './tabs/plan.vue'
import Caution from './tabs/caution.vue'
export default {
  name: 'component-yq',
  components: {
    'source-information': Information,
    'source-statistics': Statistics,
    'source-plan': Plan,
    'source-caution': Caution
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      activeIndex: this.$route.query.setTab ? this.$route.query.setTab : 'information',
      editedPlan: false
    }
  },
  mounted() {
    if (this.$route.params.hasOwnProperty('index')) {
      const index = this.$route.params.index
      if (['information', 'statistics', 'plan', 'caution'].indexOf(index) !== -1) {
        this.activeIndex = index
      }
    }
  },
  methods: {
    handleClick(tab, event) {},
    changeIndex(index) {
      this.activeIndex = index
    },
    toEditedPlan(pid) {
      this.editedPlan = pid
      this.activeIndex = 'plan'
    },
    showDelConfirm(id, title, data) {
      this.$emit('showDelConfirm', id, title, data)
    }
  }
}
</script>
<style>
#source-card > .el-card__body {
  padding-bottom: 0px;
}
/* 信源监测tabs */
#source-tabs > .el-tabs__content {
  box-shadow: none;
  padding: 0;
}
#source-information,
#source-statistics,
#source-plans,
#source-caution {
  box-shadow: inset 0 15px 15px -15px rgba(65, 70, 76, 0.07);
}
#source-plans,
#source-caution {
  padding: 23px 40px 0;
}
</style>
